@import "scss/variables";

@keyframes menu_backdrop {
  from {background-color: transparent;}
  to {background-color: $mobilemenu-backdrop-bg;}
}

//@keyframes menu_backdrop__close {
//  from {background-color: $mobilemenu-backdrop-bg;}
//  to {background-color: transparent;}
//}

//TODO remove the opacity styles from mobilemenu and mobilemenu--open and finish the mobile menu closing animation with the close__animation @keyframes.
// (the menu_backdrop__close) @keyframes will work correctly after finishing!!!

//@keyframes close__animation {
//  from {
//    visibility: hidden;
//  }
//  to {
//    visibility: visible;
//  }
//}

@media (min-width: 991px) {
  .mobilemenu {
    display: block;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 999;
    //animation-name: close__animation;
    //animation-delay: 1s;
    //animation-duration: 0s;
    //animation-fill-mode: forwards;
  }

  .mobilemenu--open {
    top: 0;
    opacity: 1;
    width: 100% !important;
    height: 100%;
    z-index: 999 !important;
    position: fixed;
    visibility: visible;
    transition-delay: 0s;
    border-bottom-right-radius: 20px;

    .mobilemenu__backdrop {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: $mobilemenu-backdrop-bg;
      transition: opacity .3s;
      opacity: 0.9;
      animation-name: menu_backdrop;
      animation-duration: .3s;
    }

    .mobilemenu__body {
      left: 0;
    }
  }

  .animtrue {
    left: -480px !important;
    transition: left .3s !important;
  }

  .backdrop__hide {
    opacity: 0 !important;
    transition: opacity .3s !important;
  }

  .mobilemenu__backdrop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: opacity .3s;
    opacity: 0.9;
    animation-name: menu_backdrop__close;
    animation-duration: .3s;
  }

  .mobilemenu__body {
    position: absolute;
    top: 0;
    left: -480px;
    background: $mobilemenu-bg;
    display: flex;
    flex-direction: column;
    width: 480px;
    border-bottom-right-radius: 20px;
    padding: 40px 40px 40px 32px;
    height: 100vh;
    transition: left .3s linear;

    .menu__header {
      display: flex;
      margin-bottom: 40px;
      flex-direction: row;
      justify-content: space-between;

      .header__logo {
        min-width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .mobile-header__menu-button {
        width: 36px;
        height: 36px;
        background: transparent;
        cursor: pointer;
      }
    }

    .mobilemenu__content {
      flex-grow: 1;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      .mobile-links__item-link {
        font-size: 20px !important;
      }

      ul:not(:first-child) {
        display: none;
      }

      .mobile-links {
        list-style: none;
        padding: 0;
        margin: 0;

        .mobile-links__item:last-child {
          border-bottom: 1px solid $mobilemenu-divider-color;
        }

        .mobile-links__item {
          .mobile-links__item-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            cursor: pointer;

            .link-label {
              display: none;
            }


            .mobile-links__item-link {
              height: 100%;
              flex-grow: 1;
              color: $mobilemenu-font-color;
              border: none;
              margin: 0;
              background-color: transparent;
              line-height: 21px;
              display: flex;
              justify-content: left;
              align-items: center;
              cursor: pointer;
              text-align: left;

              &:focus {
                outline: none;
              }
            }

            .mobile-links__item-toggle {
              border: none;
              background: transparent;
              padding: 0;
              width: 55px;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: inherit;
              fill: $mobilemenu-font-muted-color;
              transition: fill .3s;

              .mobile-links__item-arrow {
                transition: .3s;
              }
            }
          }

          .mobile-links__item-sub-links {
            visibility: hidden;
            overflow: hidden;
            height: 0;
            opacity: 0;
            transition: height .3s, opacity .3s, visibility 0s .3s;

            .mobile-links--level--1 {
              font-size: 14px;
              line-height: 14px;
            }

            //.mobile-links--level--2 {
            //  margin-left: 10px;
            //}
            //
            //.mobile-links--level--3 {
            //  margin-left: 20px;
            //}
            //
            //.mobile-links--level--4 {
            //  margin-left: 30px;
            //}
            //
            //.mobile-links--level--5 {
            //  margin-left: 40px;
            //}
            //
            //.mobile-links--level--6 {
            //  margin-left: 50px;
            //}
            //
            //.mobile-links--level--7 {
            //  margin-left: 60px;
            //}
            .mobile-links--level--2,
            .mobile-links--level--3,
            .mobile-links--level--4,
            .mobile-links--level--5,
            .mobile-links--level--6,
            .mobile-links--level--7 {
              margin-left: 20px;

            }

          }


          &.mobile-links__item--open {
            & > .mobile-links__item-sub-links {
              visibility: visible;
              height: 100%;
              opacity: 1;
              transition-delay: 0s, 0s, 0s;

              ul > li:last-child {
                div {
                  border-bottom: 0;
                }
              }

              .mobile-links__item-link {
                font-size: 17px !important;
                text-transform: lowercase;
              }

              .mobile-links__item-link > span::first-letter {
                text-transform: uppercase;
              }

            }

            & > .mobile-links__item-title {
              .mobile-links__item-arrow {
                transition: .3s;
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .mob-links__item {
        border-bottom: 1px solid #ebebeb;
        padding: 18px 5px;
        margin: 0;

        .mobile-links__blok {
          display: flex;
          gap: 15px;
          align-items: center;
          font-size: 16px;
          color: #3d464d;
        }
      }
    }

    .mobileMenuFooter {
      display: none;

      .mobile-footer-dropdown {
        .mobile-footer-dropdown-divs {
          min-height: 60px;
          border-bottom: 1px solid white;
          font-size: 15px;
          color: #3d464d;
          display: flex;
          align-items: center;

          .footer-contacts {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            .footer-title {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin: 10px 0;
              padding: 0 10px;
              font-size: 16px;
              gap: 10px;

              &.footer-title-opened {
                margin-top: 18px;

                .mobile-footer-arrow {
                  svg {
                    transform: rotate(180deg);
                    transition: .3s;
                  }
                }
              }

              .mobile-footer-arrow {
                margin-left: auto;
                transition: transform .3s;

                svg {
                  transition: .3s;

                  path {
                    fill: #999 !important;
                  }
                }
              }
            }

            .footer-contacts__contacts {
              flex-direction: column;
              gap: 10px;
              font-size: 15px;
              list-style: none;
              margin: 10px 0;
              padding: 0 40px;
              text-align: left;

              .contactsAddress {
                white-space: pre-line;
                position: relative;
                padding: 0;
                line-height: 19px;

                p, a {
                  color: $body-font-color;
                  font-size: 16px;
                  width: 100%;
                  word-wrap: break-word;
                }
              }
            }
          }
        }

        .mobile-footer-dropdown-last-divs {
          min-height: 60px;
          display: flex;
          align-items: center;

          .footer-newsletter__social-links {
            display: flex;
            justify-content: flex-start;
            padding: 0 10px;

            .social-links__list {
              list-style: none;
              display: flex;
              flex-wrap: wrap;
              gap: 14px;

              .social-links__item {
                .social-links__link {
                  span {
                    position: relative !important;
                    width: 30px !important;
                    height: 30px !important;
                  }

                  img {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .mobilemenu {
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: block;
    position: fixed;
    visibility: hidden;
    overflow-y: scroll;
    transition: visibility 0s .3s;

    &.mobilemenu--open {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;

      .mobilemenu__backdrop {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        transition: opacity .3s;
        background: $mobilemenu-backdrop-bg;
        animation-name: menu_backdrop;
        animation-duration: .3s;
      }

      .mobilemenu__body {
        left: 0;
      }
    }

    .animtrue {
      left: -480px !important;
      transition: left .3s !important;
    }

    .backdrop__hide {
      opacity: 0 !important;
      transition: opacity .3s !important;
    }

    .mobilemenu__body {
      top: 0;
      left: -480px;
      width: 480px;
      height: 100vh;
      display: flex;
      position: absolute;
      flex-direction: column;
      background: $mobilemenu-bg;
      transition: left .3s linear;
      padding: 40px 40px 40px 32px;
      box-shadow: $mobilemenu-shadow;
      border-bottom-right-radius: 20px;

      .menu__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .header__logo {
          min-width: 200px;
          height: 100%;
          display: flex;
          align-items: center;
        }

        button {
          width: 36px;
          height: 36px;
          border-radius: 2px;
          background: transparent;
          cursor: pointer;
          transition: fill .3s, background .3s;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .mobilemenu__content {
        flex-grow: 1;
        overflow-y: scroll;
        margin-bottom: 80px;
        -webkit-overflow-scrolling: touch;

        .mobile-links {
          list-style: none;
          padding: 0;
          margin: 0;

          .mobile-links__item {
            &:last-child {
              border-bottom: none;
            }

            .mobile-links__item-title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              min-height: 60px;
              padding: 10px 0;
              border-bottom: 1px solid $mobilemenu-divider-color;

              .link-label {
                width: 100%;
                display: flex;
                text-align: left;
                align-items: center;
                gap: 15px;
              }

              .mobile-links__item-link {
                height: 100%;
                flex-grow: 1;
                color: $mobilemenu-font-color;
                border: none;
                margin: 0;
                background-color: transparent;
                line-height: 21px;
                font-size: 20px;
                display: flex;
                justify-content: left;
                align-items: center;
                text-align: left;

                &:focus {
                  outline: none;
                }
              }

              .mobile-links__item-toggle {
                border: none;
                background: transparent;
                padding: 0;
                width: 55px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: inherit;
                fill: $mobilemenu-font-muted-color;
                transition: fill .3s;

                .mobile-links__item-arrow {
                  transition: .3s;
                }
              }
            }

            .mobile-links__item-sub-links {
              visibility: hidden;
              overflow: hidden;
              height: 0;
              opacity: 0;
              transition: height .3s, opacity .3s, visibility 0s .3s;

              .mobile-links--level--1 {
                font-size: 14px;
                line-height: 14px;
              }

              //.mobile-links--level--2 {
              //  margin-left: 10px;
              //}
              //
              //.mobile-links--level--3 {
              //  margin-left: 20px;
              //}
              //
              //.mobile-links--level--4 {
              //  margin-left: 30px;
              //}
              //
              //.mobile-links--level--5 {
              //  margin-left: 40px;
              //}
              //
              //.mobile-links--level--6 {
              //  margin-left: 50px;
              //}
              //
              //.mobile-links--level--7 {
              //  margin-left: 60px;
              //}
              .mobile-links--level--2,
              .mobile-links--level--3,
              .mobile-links--level--4,
              .mobile-links--level--5,
              .mobile-links--level--6,
              .mobile-links--level--7 {
                margin-left: 20px;

              }

            }

            &.mobile-links__item--open {
              & > .mobile-links__item-sub-links {
                visibility: visible;
                height: 100%;
                opacity: 1;
                transition-delay: 0s, 0s, 0s;

                .mobile-links__item-link {
                  font-size: 16px !important;
                  text-transform: lowercase;
                }

                .mobile-links__item-link > span::first-letter {
                  text-transform: uppercase;
                }
              }

              & > .mobile-links__item-title {
                .mobile-links__item-arrow {
                  transition: .3s;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        .mob-links__item {
          border-bottom: 1px solid #ebebeb;
          padding: 18px 5px;
          margin: 0;

          .mobile-links__blok {
            display: flex;
            gap: 15px;
            align-items: center;
            font-size: 18px;
            color: #121212;
          }
        }
      }

      .mobileMenuFooter {
        display: none;

        //  .mobile-footer-dropdown {
        //    .mobile-footer-dropdown-divs {
        //      min-height: 60px;
        //      border-bottom: 1px solid white;
        //      font-size: 15px;
        //      color: #3d464d;
        //      display: flex;
        //      align-items: center;
        //
        //      .footer-contacts {
        //        display: flex;
        //        flex-direction: column;
        //        justify-content: center;
        //        width: 100%;
        //
        //        .footer-title {
        //          width: 100%;
        //          display: flex;
        //          justify-content: flex-start;
        //          align-items: center;
        //          margin: 10px 0;
        //          padding: 0 10px;
        //          font-size: 16px;
        //          gap: 10px;
        //
        //          &.footer-title-opened {
        //            margin-top: 18px;
        //
        //            .mobile-footer-arrow {
        //              svg {
        //                transform: rotate(180deg);
        //                transition: .3s;
        //              }
        //            }
        //          }
        //
        //          .mobile-footer-arrow {
        //            margin-left: auto;
        //            transition: transform .3s;
        //
        //            svg {
        //              transition: .3s;
        //
        //              path{
        //                fill: #999!important;
        //              }
        //            }
        //          }
        //        }
        //
        //        .footer-contacts__contacts {
        //          flex-direction: column;
        //          gap: 10px;
        //          font-size: 15px;
        //          list-style: none;
        //          margin: 10px 0;
        //          padding: 0 40px;
        //          text-align: left;
        //
        //          .contactsAddress {
        //            position: relative;
        //            padding: 0;
        //            line-height: 19px;
        //
        //            p, a {
        //              color: $body-font-color;
        //              font-size: 16px;
        //              width: 100%;
        //              word-wrap: break-word;
        //            }
        //          }
        //        }
        //      }
        //    }
        //
        //    .mobile-footer-dropdown-last-divs {
        //      min-height: 60px;
        //      display: flex;
        //      align-items: center;
        //
        //      .footer-newsletter__social-links {
        //        display: flex;
        //        justify-content: flex-start;
        //        padding: 0 10px;
        //
        //        .social-links__list {
        //          list-style: none;
        //          display: flex;
        //          flex-wrap: wrap;
        //          gap: 14px;
        //
        //          .social-links__item {
        //            .social-links__link {
        //              span {
        //                position: relative !important;
        //                width: 30px !important;
        //                height: 30px !important;
        //              }
        //
        //              img {
        //                width: 100% !important;
        //              }
        //            }
        //          }
        //        }
        //      }
        //    }
        //  }
      }
    }
  }
}

@media screen and (max-width: 600px) {

  .mobilemenu--open {
    .mobilemenu__body {
      left: 0;
    }
  }

  .animtrue {
    left: -300px !important;
    transition: left .3s !important;
  }

  .backdrop__hide {
    opacity: 0 !important;
    transition: opacity .3s !important;
  }

  .mobilemenu {
    .mobilemenu__body {
      left: -300px;
      width: 300px;
      padding: 15px;

      .mobileMenuFooter {
        .mobile-footer-dropdown {
          width: 100%;
          border-bottom: 1px solid silver;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .mobilemenu__open {
    .mobilemenu__backdrop {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: $mobilemenu-backdrop-bg;
      opacity: .9;
      transition: opacity .3s;
      animation-name: menu_backdrop;
      animation-duration: .3s;
    }

    .mobilemenu__body {
      position: absolute;
      top: 0;
      background: $mobilemenu-bg;
      box-shadow: $mobilemenu-shadow;
      transition: transform .3s;
      display: flex;
      flex-direction: column;

      .mobilemenu__content {
        flex-grow: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        //margin: 40px 10px 80px;
      }

      .mobileMenuFooter-fms {
        padding: 0 10px;
        background: rgba(13, 71, 161, 0.05);

        .mobile-footer-dropdown {
          border-bottom: 1px solid silver;
          width: 100%;

          .mobile-footer-dropdown-divs {
            min-height: 60px;
            border-bottom: 1px solid white;
            font-size: 15px;
            color: #3d464d;
            display: flex;
            align-items: center;

            .footer-contacts {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}